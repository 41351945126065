import React from 'react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { BrowserRouter } from "react-router-dom";
import Base from './routes/Base';
import Swiper from './Components/swiper';
import Support from './Components/Support';

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
      
       
        <div className="base_container">
          <Navbar />
        
          <Base />
          <div className='swpper'>
            
           <Swiper />
           </div>
           <Support /> {/* Added here */}
        </div>
        
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
