import React from "react";
import { useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js"; // Import html2pdf for PDF saving
import '../css/success.css';
import logo from '../assets/log.png';
import logoo from '../assets/logoo.png';
const Success = () => {
  const location = useLocation();
  const { finalizeData, formData } = location.state || {};

  // Function to save the receipt as PDF
  const handleSaveAsPDF = () => {
    const element = document.getElementById("receipt");
    const filename = `payment_receipt_${finalizeData?.customerName || "unknown"}_${finalizeData?.reference || "reference"}.pdf`;
    const options = {
      margin: 0,
      filename: filename,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: "in", format: "A5", orientation: "landscape" },
    };
    html2pdf().from(element).set(options).save();
  };

  // Function to share the receipt using the Web Share API
  const handleShare = async () => {
    const element = document.getElementById("receipt");
    const filename = `payment_receipt_${finalizeData?.customerName || "unknown"}_${finalizeData?.reference || "reference"}.pdf`;
    const options = {
      margin: 0,
      filename: filename,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: "in", format: "A5", orientation: "landscape" },
    };
  
    try {
      // Generate the PDF and convert to Blob
      const pdfBlob = await html2pdf().from(element).set(options).outputPdf("blob");
  
      // Check if the browser supports file sharing
      if (navigator.canShare && navigator.canShare({ files: [new File([pdfBlob], filename)] })) {
        const file = new File([pdfBlob], filename, { type: "application/pdf" });
        navigator
          .share({
            files: [file],
            title: `Payment Receipt for ${finalizeData?.customerName}`,
            text: "Here is your payment receipt.",
          })
          .then(() => console.log("Shared successfully"))
          .catch((error) => console.error("Error sharing file:", error));
      } else {
        alert("File sharing is not supported on your browser.");
      }
    } catch (error) {
      console.error("Error generating or sharing PDF:", error);
      alert("Failed to share the receipt.");
    }
  };
  

  return (
    <div className="container">
      <div className="receipt-container" id="receipt">
        {finalizeData && (
          <div className="receipt-content">
            <div className="title-container">
              <img src={logo} alt="Logo" className="logo" />
            </div>
            {formData?.accountType === "Prepaid" && (
              <>
                <div className="amount">
                  <span className=""> ₦{finalizeData.amount} </span>
                </div>
                <div className="text_message">
                  <span className="message">Transaction Successful</span>
                  <span className="date">{finalizeData.pub_date}</span>
                </div>
                <p className="token"><strong>Token: </strong> <strong>{finalizeData.result}</strong></p>
                <p><strong>Meter Number: </strong> {formData?.MeterNumber}</p>
                <p><strong>Amount: </strong> ₦{finalizeData.amount}</p>
                <p><strong>Total Unit Vended: </strong> {finalizeData.totalUnitVended}</p>
                <p><strong>Tariff Rate: </strong> {finalizeData.tariffRate}</p>
                <p><strong>VAT: </strong> {finalizeData.vat}</p>
              </>
            )}
            {formData?.accountType === "Postpaid" && (
              <>
                <div className="amount">
                  <span className=""> ₦{finalizeData.paymentChannelAmount} </span>
                </div>
                <div className="text_message">
                  <span className="message">Transaction Successful</span>
                  <span className="date">{finalizeData.pub_date}</span>
                </div>
                <p><strong>Customer Account No: </strong> {formData?.MeterNumber}</p>
                <p><strong>Mode Of Payment: </strong> {finalizeData.modeOfPayment}</p>
                <p><strong>Type of Payment: </strong> {finalizeData.typesOfPayment}</p>
                <p><strong>Amount: </strong> ₦{finalizeData.paymentChannelAmount}</p>
                <p className="small-text"><strong>Customer Address: </strong> {finalizeData.customerAddress}</p>
                <p><strong>Customer Transformer: </strong> {finalizeData.customerTransformer}</p>
              </>
            )}
            <p className="small-text"><strong>Transaction ID: </strong> {finalizeData.id}</p>
            <p><strong>Customer Name: </strong> {finalizeData.customerName}</p>
            <p><strong>Phone Number: </strong> {finalizeData.telephoneNumber}</p>
            <p><strong>State: </strong> {finalizeData.customerState}</p>
            <p><strong>Reference: </strong> {finalizeData.reference}</p>
          </div>
         
        )}
         <div className="reciept_footer">
  <img src={logoo} alt="BlumenTech Logo" className="footer-logo" />
  <span>POWERED BY BLUMENTECH</span>
</div>

      </div>
      <div className="button-container">
        <button className="action-button" onClick={handleSaveAsPDF}>
          Save as PDF
        </button>
        <button className="action-button" onClick={handleShare}>
          Share
        </button>
      </div>
    </div>
  );
};

export default Success;
