import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiArrowDownSFill } from "react-icons/ri";
import { FaSpinner } from "react-icons/fa";
import Loader from "./Loader"; // Import Loader component
import "../css/form.css";

const MeterForm = () => {
  const [formData, setFormData] = useState({
    paymentType: "Electricity",
    userLocation: "",
    accountType: "Prepaid",
    MeterNumber: "",
    phoneNumber: "",
    email: "",
    amount: "",
  });

  const [electricityAmount, setElectricityAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [customerInfo, setCustomerInfo] = useState(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [token, setToken] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the token on component mount
    const fetchToken = async () => {
      try {
        const response = await fetch(
          "https://kaduna-electric.onrender.com/custom-user/api/token/",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: "raymond", password: "rayray123" }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          setToken(data.access);
        } else {
          const errorDetails = await response.json();
          console.error("Failed to fetch token:", errorDetails);
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };
    

    fetchToken();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    const amount = parseFloat(value);
    setElectricityAmount(amount.toString());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const verifyUrl = `https://kaduna-electric.onrender.com/payment/verify-customer/${formData.MeterNumber}/`;
      const response = await fetch(verifyUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setCustomerInfo(result.data);
        setShowVerificationModal(true);
      } else {
        console.error("Customer verification failed.");
      }
    } catch (error) {
      console.error("Error verifying customer:", error);
    } finally {
      setLoading(false);
    }
  };

  const proceedToPayment = async () => {
    setShowVerificationModal(false);
    setLoading(true);

    const postUrl =
      formData.accountType === "Prepaid"
        ? "https://kaduna-electric.onrender.com/payment/init/online-pay-prepaid/"
        : "https://kaduna-electric.onrender.com/payment/init/online-pay-postpaid/";

    const payload = formData.accountType === "Prepaid"
      ? {
          agentId: "1",
          meterNumber: formData.MeterNumber,
          amount: electricityAmount,
          locationOfPayment: "kaduna",
          notes: "Payment for electricity",
          telephoneNumber: formData.phoneNumber,
          laitude: "1",
          longitude: "1",
          payment_type: "web payment",
        }
      : {
          agentId: "1",
          customerAccountNo: formData.MeterNumber,
          paymentChannelAmount: electricityAmount,
          locationOfPayment: "kaduna",
          notes: "Payment for electricity",
          telephoneNumber: formData.phoneNumber,
          laitude: "1",
          longitude: "1",
          payment_type: "web payment",
        };

    try {
      const response = await fetch(postUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data && data.data.checkoutUrl) {
          setIframeSrc(data.data.checkoutUrl);
          setShowModal(true);

          const reference = data.data.reference;
          window.addEventListener("message", async (event) => {
            console.log("Message received from iframe:", event.data); // Log the received message
            if (event.data?.event === "success" && event.data?.data?.status === "success") {
              console.log("Payment success detected, proceeding to finalize...");
              const reference = event.data.data.reference; // Extract the reference from the event
              await finalizePayment(reference, formData.MeterNumber);
            } else {
              console.warn("Unexpected message received:", event.data);
            }
          });
        } else {
          console.error("checkoutUrl not found in response.");
          alert("Unable to proceed: Payment URL missing.");
        }
      } else {
        console.error("Payment initiation failed.");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setLoading(false);
    }
  };

  const finalizePayment = async (reference, meterNumber) => {
    setLoading(true);
    try {
      const finalizeUrl = `https://kaduna-electric.onrender.com/payment/finalize/online-pay/${reference}/${meterNumber}/`;
      const response = await fetch(finalizeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const finalizeData = await response.json();
        console.log("Finalize Data:", finalizeData);
        setShowLoader(true);
        setTimeout(() => {
          navigate("/Success", { state: { finalizeData, formData } });
        }, 4000); // Delay increased to 30 seconds
      } else {
        console.error("Failed to finalize payment.");
      }
    } catch (error) {
      console.error("Error finalizing payment:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const closeModal = () => {
    setShowModal(false);
    setIframeSrc("");
  };

 

  return (
    <div className="form-container paddng_form">
      {showLoader && <Loader />}
      <form className="payment-form" onSubmit={handleSubmit}>
        <h2>Payment Form</h2>
        <div className="form-group">
          <label htmlFor="accountType">Account Type</label>
          <div className="select-wrapper">
            <select
              id="accountType"
              name="accountType"
              value={formData.accountType}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="Prepaid">Prepaid</option>
              <option value="Postpaid">Postpaid</option>
            </select>
            <RiArrowDownSFill className="dropdownarrow" />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="MeterNumber">
            {formData.accountType === "Prepaid" ? "Meter Number" : "Customer Account Number"}
          </label>
          <input
            type="text"
            id="MeterNumber"
            name="MeterNumber"
            value={formData.MeterNumber}
            onChange={handleChange}
            placeholder={`Enter ${formData.accountType === "Prepaid" ? "meter number" : "customer account number"} here`}
            required
            disabled={loading}
          />
        </div>
      
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Enter phone number"
            required
            disabled={loading}
          />
        </div>
        <div className="form-group">
          <label htmlFor="electricityAmount">
            {formData.accountType === "Prepaid" ? "Electricity Amount (₦)" : "Payment Channel Amount (₦)"}
          </label>
          <input
            type="number"
            id="electricityAmount"
            name="electricityAmount"
            value={electricityAmount}
            onChange={handleAmountChange}
            min="0"
            required
            disabled={loading}
          />
        </div>
        <div className="button-container">
          <button type="submit" className="submit" disabled={loading}>
            {loading ? <FaSpinner className="spinner rotating" /> : "Verify & Proceed"}
          </button>
        </div>
      </form>

      {showVerificationModal && customerInfo && (
        <div className="modal-overlay-info">
          <div className="infomodal">
            <button className="exit_button" onClick={() => setShowVerificationModal(false)}>x</button>
            <h3>User Info</h3>
            <div className="info_content">
              <p><strong>Customer Name:</strong> {customerInfo.customerName || "N/A"}</p>
              <p><strong>{formData.accountType === "Postpaid" ? "Account Number" : "Meter Number"}:</strong> 
   {formData.accountType === "Postpaid" ? customerInfo.customerAccountNo || "N/A" : customerInfo.meterNumber || "N/A"}
</p>

              <p><strong>Address:</strong> {customerInfo.customerAddress || "N/A"}</p>
              <p><strong>State:</strong> {customerInfo.customerState || "N/A"}</p>
              <p><strong>Transformer:</strong> {customerInfo.customerTransformer || "N/A"}</p>
              <div className="button-container">
                <button className="submit" onClick={proceedToPayment}>
                  {loading ? <FaSpinner className="spinner rotating" /> : "Proceed Payment"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-button" onClick={closeModal}>X</button>
            <iframe
              src={iframeSrc}
              title="Payment Checkout"
              style={{ width: "100%", height: "500px", border: "none" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MeterForm;

