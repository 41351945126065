import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, EffectFade, Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/zoom';
import '../css/swiper.css'; // Updated CSS file

const images = [
    require('../assets/1.jpg'),
    require('../assets/2.jpg'),
    require('../assets/3.jpg'),
];

const SwiperComponent = () => {
    return (
        <div className="swiper-background">
            {/* Overlay */}
            <div className="swiper-overlay"></div>

            <Swiper
                modules={[Pagination, Autoplay, EffectFade, Zoom]} // Include EffectFade module
                autoplay={{ delay: 8000, disableOnInteraction: false }}
                loop={true}
                effect="fade" // Apply the fade effect
                zoom={true} // Enable zoom
                fadeEffect={{ crossFade: true }} // Optional: smooth cross-fade
                pagination={{ clickable: true }} // Enable pagination
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div className="swiper-zoom-container">
                            <img className="carousel-image" src={image} alt={`Slide ${index + 1}`} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default SwiperComponent;
