import React, { useState, useEffect } from 'react';
import { MdSupportAgent, MdEmail, MdLocationOn, MdPhone } from 'react-icons/md';
import { FaGlobe } from 'react-icons/fa';
import '../css/Support.css';

const Support = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState(''); // Manage success notification
  const [token, setToken] = useState(''); // Store the token

  // Fetch the token on component mount
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          'https://kaduna-electric.onrender.com/custom-user/api/token/',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: 'raymond', password: 'rayray123' }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          setToken(data.access); // Save the access token
        } else {
          const errorDetails = await response.json();
          console.error('Failed to fetch token:', errorDetails);
        }
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();
  }, []); // Run only once when the component mounts

  const toggleForm = () => {
    setShowForm(!showForm);
    setSuccessMessage(''); // Reset success message when toggling form
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      status: true,
      pub_date: new Date().toISOString(),
    };

    try {
      const response = await fetch('https://kaduna-electric.onrender.com/inbox/inbox/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include token in the Authorization header
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setSuccessMessage('Message sent successfully!'); // Set success message
        setFormData({ name: '', email: '', phone: '', message: '' }); // Reset form

        // Automatically hide the success message after 1 second
        setTimeout(() => {
          setSuccessMessage('');
        }, 1000); // 1000ms = 1 second
      } else {
        const errorData = await response.json();
        alert(`Failed to send message: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <>
      {/* Support Icon */}
      <div className="support-icon" onClick={toggleForm}>
        <MdSupportAgent size={30} color="white" />
      </div>

      {/* Contact Form Overlay */}
      {showForm && (
        <div className="contact-form-overlay">
          <div className="contact-container">
            <button className="close-btn" onClick={toggleForm}>
              ✖
            </button>

            {/* Success Notification */}
            {successMessage && (
              <div className="success-notification">
                <p>{successMessage}</p>
              </div>
            )}

            {/* Form Column */}
            <div className="form-column">
              <div className="send">
                <h3>Send us a message</h3>
              </div>

              <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="placeholder"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="placeholder"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="phone">Phone Number</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="placeholder"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className="placeholder"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />

                <div className="btnn">
                  <button type="submit" className="send-btn">
                    Send
                  </button>
                </div>
              </form>
            </div>

            {/* Info Column */}
            <div className="info-column">
              <div className="send">
                <h3>Contact Us</h3>
              </div>

              <div className="suggestion">
                <p>We welcome your valuable suggestions and constructive feedback.</p>
              </div>

              <div className="dbox">
                <MdLocationOn size={30} className="info-icon" />
                <div className="text">
                  <p>1-2 Ahmadu Bello Way, Kaduna, Kaduna State.</p>
                </div>
              </div>
              <div className="dbox">
                <MdPhone size={30} className="info-icon" />
                <div className="text">
                  <p>
                    <a href="tel:+2349055540389">+234 905 554 0389</a>
                  </p>
                </div>
              </div>
              <div className="dbox">
                <MdPhone size={30} className="info-icon" />
                <div className="text">
                  <p>
                    <a href="tel:+2348033874843">+234 803 387 4843</a>
                  </p>
                </div>
              </div>

              <div className="dbox">
                <MdEmail size={30} className="info-icon" />
                <div className="text">
                  <p>
                    <a href="mailto:info@kadunaelectric.com">info@kadunaelectric.com</a>
                  </p>
                </div>
              </div>
              <div className="dbox">
                <FaGlobe size={30} className="info-icon" />
                <div className="text">
                  <p>
                    <a href="https://kadunaelectric.com/">kadunaelectric.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Support;
